import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import withRouter from 'react-router/withRouter';
import loadData from './brands_that_matter_page_state_manager';
import striptags from 'striptags';
import loadable from '@loadable/component';
import { UserConsumer } from '../../../components/context/userContext';
import BlueConicPremiumPaywall from '../../../components/BlueConicPremiumPaywall/BlueConicPremiumPaywall';

const NotFound = loadable(() =>
  import('../../../components/not_found_component/not_found_component')
);
const AdContainer = loadable(() =>
  import('../../../components/ad/AdContainer')
);
const Social = loadable(() => import('../../../components/social/social'));
const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);
const BrandsThatMatter = loadable(
  () => import('../../../fc-components/main/containers'),
  {
    resolveComponent: components => components.BrandsThatMatter
  }
);
const Section = loadable(
  () => import('../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Section
  }
);
const Text = loadable(() => import('../../../fc-components/main/components'), {
  resolveComponent: components => components.Text
});
const HR = loadable(() => import('../../../fc-components/main/components'), {
  resolveComponent: components => components.HR
});
const Image = loadable(() => import('../../../fc-components/main/components'), {
  resolveComponent: components => components.Image
});

const BrandsThatMatterPage = props => {
  const fullPackageObj = get(props, 'brandsThatMatterPage', null);
  const packageData = get(fullPackageObj, 'data.wpData');
  const headerExcerpt = get(packageData, 'excerpt.rendered', '');
  const packageBlurb = get(packageData, 'acf.franchise_blurb', '');
  const wpTitle = get(packageData, 'title.rendered');
  const socialTitle = wpTitle ? striptags(wpTitle).trim() : '';
  const fullBrandList = get(packageData, 'acf.brandsThatMatterCompanies', []);
  const franchiseAnimatedFeatureImageDesktop = get(
    packageData,
    'acf.franchise_images.franchise_animated_svg_desktop'
  );
  const blurbImage = get(
    packageData,
    'acf.franchise_images.franchise_blurb_image'
  );

  const brandActive = el => {
    const element = el;
    const bottomCushion = 50;
    const brandDescription = element.querySelector('.brand-description');
    element.classList.add('active');
    element.style.marginBottom = `${brandDescription.offsetHeight +
      bottomCushion}px`;
    brandDescription.classList.add('slide-bottom');
  };

  const brandInactive = el => {
    const element = el;
    element.classList.remove('active');
    element.classList.remove('slide-bottom');
    element.style.marginBottom = `initial`;
  };

  const toggleBrandDisplay = event => {
    event.preventDefault();
    const currentTarget = get(event, 'currentTarget', null);

    if (currentTarget.classList.contains('active')) {
      // User clicked on active element, just de-activate
      brandInactive(currentTarget);
    } else {
      // Hide all previously active elements
      Array.from(get(currentTarget, 'parentElement.children', [])).forEach(
        element => {
          brandInactive(element);
        }
      );

      // Set current element as active
      brandActive(currentTarget);
    }
  };

  const handleBrandCategories = brands => {
    const finalArray = brands.reduce((acc, item) => {
      item.companyCategories.forEach((category, i) => {
        if (item.companyCategories[i] in acc) {
          acc[item.companyCategories[i]] = [
            ...acc[item.companyCategories[i]],
            item
          ];
        } else {
          acc[item.companyCategories[i]] = [item];
        }
      });
      return acc;
    }, {});

    return finalArray;
  };

  const brandSubCategories = handleBrandCategories(fullBrandList);
  const mainList = brandSubCategories['Main List'];
  delete brandSubCategories['Main List'];

  // Initiate from Redux
  const { brandsThatMatterPage, status } = props;

  // Handle Lazy Loading & Errors
  if (brandsThatMatterPage.error || status === 404 || isEmpty(fullPackageObj)) {
    return (
      <section className="best-workplaces-for-innovators-page">
        <article className="best-workplaces-for-innovators__main--error-pg">
          <NotFound />
        </article>
      </section>
    );
  }

  if (brandsThatMatterPage.isLoading) {
    return (
      <section className="best-workplaces-for-innovators-page">
        <article className="best-workplaces-for-innovators__main">
          <LoadingSpinner />
        </article>
      </section>
    );
  }

  return (
    <BrandsThatMatter year={2021}>
      <div id="BrandsThatMatter__lander__2021">
        <h1 className="overall-page-hed">
          2021 Fast Company Brands That Matter
        </h1>
        <Section name="franchise__topper" bgColor="#114DBB" maxWidth="1050">
          <div className="topper__flex">
            <div className="left">
              <div class="franchisetitleHolder">
                <div class="title1">Brands</div>
                <div class="title2">
                  <img
                    alt=""
                    src="https://images.fastcompany.net/image/upload/v1635168971/fcweb/Screen_Shot_2021-10-25_at_9.36.01_AM_f2mwa5.png"
                  />
                  <div>That</div>
                </div>
                <div class="title3">Matter</div>
              </div>
              <div className="textBacker">
                <span dangerouslySetInnerHTML={{ __html: headerExcerpt }} />
              </div>
            </div>
            <div className="right">
              <img
                className="animtedHeaderLogo"
                alt="Animated Brand That Matter franchise logo"
                src={franchiseAnimatedFeatureImageDesktop}
              />
            </div>
          </div>
        </Section>
        <div className="btm-content-container">
          <Social
            enableSticky={true}
            empty={false}
            disableStyle={false}
            topValue={111}
            text={encodeURIComponent(socialTitle)}
            uri="https://www.fastcompany.com/brands-that-matter/2021"
            active={true}
          />
          <div className="main-content-well">
            <Section name="franchise__blurb" maxWidth="700">
              <Text as="div">
                <Image image={blurbImage} width="85px" float="left" />
                <div dangerouslySetInnerHTML={{ __html: packageBlurb }} />
              </Text>
            </Section>
            <Section noPadding name="franchise__contents">
              <UserConsumer>
                {({ hasPaywallAccess }) => {
                  const showDrawer = !hasPaywallAccess();
                  const brandsList = showDrawer
                    ? [...mainList].slice(0, 5)
                    : mainList;

                  return (
                    <>
                      <div className="list__container">
                        {brandsList.map((brand, index) => (
                          <div
                            key={`main-list-brand-${index}`}
                            className={`main-list-brand-item treatment-${index %
                              4}`}
                            onClick={toggleBrandDisplay}
                            data-brand-name={brand.companyName}
                          >
                            <div className="brand-name">
                              {get(brand, 'companyAnimation', false) && (
                                <div className="brand-animation">
                                  <img
                                    alt=""
                                    src={get(brand, 'companyAnimation.url')}
                                  />
                                </div>
                              )}
                              <div className="activeHighlight" />
                              <span
                                style={
                                  brand.companyName === 'Cosmopolitan Magazine'
                                    ? { fontStyle: 'italic' }
                                    : {}
                                }
                              >
                                {brand.companyName}
                              </span>
                              <div className="brandBorder" />
                            </div>
                            <div className="brand-description">
                              <div>
                                <div
                                  className="brand-description__text"
                                  dangerouslySetInnerHTML={{
                                    __html: brand.companyDescription.concat(
                                      ReactDOMServer.renderToString(
                                        <div className="close-btn">
                                          &nbsp;CLOSE
                                        </div>
                                      )
                                    )
                                  }}
                                />
                                <HR height={15} color="#E6FF33" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {showDrawer && <BlueConicPremiumPaywall />}
                    </>
                  );
                }}
              </UserConsumer>
            </Section>

            <AdContainer type="break" />

            <UserConsumer>
              {({ hasPaywallAccess }) => {
                if (!hasPaywallAccess()) {
                  return null;
                }

                return (
                  <Section noPadding name="franchise__contents">
                    {Object.keys(brandSubCategories)
                      .sort((a, b) => a.localeCompare(b))
                      .map((key, index) => (
                        <div
                          key={`subcategory-list-container-${index}`}
                          className="subcategory__container"
                        >
                          <div className="subcategory__container__heading">
                            <img
                              alt=""
                              src="https://images.fastcompany.net/image/upload/v1634770708/fcweb/arrow_dummy_nt3eks.png"
                            />
                            <span>{key}</span>
                          </div>
                          <div className="list__container list__container__sublist">
                            {brandSubCategories[key]
                              .sort((a, b) =>
                                a.companyName.localeCompare(b.companyName)
                              )
                              .map((brand, i) => (
                                <div
                                  key={`main-list-brand-${i}`}
                                  className={`main-list-brand-item treatment-${i %
                                    4}`}
                                  onClick={toggleBrandDisplay}
                                  data-brand-name={brand.companyName}
                                >
                                  <div className="brand-name">
                                    {get(brand, 'companyAnimation', false) && (
                                      <div className="brand-animation">
                                        <img
                                          alt=""
                                          src={get(
                                            brand,
                                            'companyAnimation.url'
                                          )}
                                        />
                                      </div>
                                    )}
                                    <div className="activeHighlight" />
                                    <span>{brand.companyName}</span>
                                    <div className="brandBorder" />
                                  </div>
                                  <div className="brand-description">
                                    <div>
                                      <div
                                        className="brand-description__text"
                                        dangerouslySetInnerHTML={{
                                          __html: brand.companyDescription.concat(
                                            ReactDOMServer.renderToString(
                                              <div className="close-btn">
                                                &nbsp;CLOSE
                                              </div>
                                            )
                                          )
                                        }}
                                      />
                                      <HR height={15} color="#E6FF33" />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </Section>
                );
              }}
            </UserConsumer>
          </div>
        </div>
      </div>
    </BrandsThatMatter>
  );
};

// SSR Loading of data via Redux
BrandsThatMatterPage.loadData = store => loadData(store);

function mapStateToProps(state = {}) {
  return {
    status: state.status.code,
    brandsThatMatterPage: get(state, 'brandsThatMatterPage') || {
      error: true
    },
    config: state.config
  };
}

BrandsThatMatterPage.propTypes = {
  status: PropTypes.number.isRequired,
  brandsThatMatterPage: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
  }).isRequired
};

export default withRouter(connect(mapStateToProps)(BrandsThatMatterPage));
